import React, {Component, Fragment} from 'react';
import {
    Segment,
    Grid,
    Message,
    Icon,
    Header,
    Container,
    Button,
    Input,
    TextArea,
    Select,
    Form,
    Label,
    Progress
} from 'semantic-ui-react';
import Dropzone from 'react-dropzone';
import * as model from "../models/Models";
import './UploadView.css';
import Utils from '../Utils';
import 'moment/locale/it.js';
import {DatePickerInput} from 'rc-datepicker';
import 'rc-datepicker/lib/style.css';

interface uploadViewState {
    video: File,
    messageError: string,
    loading: boolean,
    metadataSaved: boolean,
    title: string,
    language: Language,
    secondLanguage: Language,
    description: string,
    tags: any[],
    tag: string,
    messageVisible: boolean,
    rtmplink: string | undefined,
    videoId: string,
    languageOptions: any[],
    videoDate: Date,
    progressBar: string,
    pageTitle: string,
    noSubtitled: boolean
}

interface Language {
    code: string,
    description: string
}

class ErrorMessage extends Component<{ onDismiss: () => void, messageError: string }> {
    render() {
        return <Message negative onDismiss={this.props.onDismiss}>
            <Message.Header>
                {this.props.messageError}
            </Message.Header>
        </Message>;
    }
}

const axios = require("axios").default;

export default class UploadView extends Component<any, uploadViewState> {
    constructor(props: any) {
        super(props);
        this.state = {
            video: {} as File,
            messageError: '',
            loading: false,
            metadataSaved: false,
            title: '',
            description: '',
            language: {} as Language,
            secondLanguage: {} as Language,
            tag: '',
            tags: [],
            messageVisible: false,
            rtmplink: undefined,
            videoId: '',
            languageOptions: [],
            videoDate: new Date(),
            progressBar: "",
            pageTitle: "Video Upload",
            noSubtitled: false
        };
        this.onUploadVideo = this.onUploadVideo.bind(this);
        this.clickOnSaveMetadata = this.clickOnSaveMetadata.bind(this);
        this.backToMetadata = this.backToMetadata.bind(this);
        this.addTag = this.addTag.bind(this);
        this.deleteTag = this.deleteTag.bind(this);
        this.handleDismiss = this.handleDismiss.bind(this);
        this.generateRTMP = this.generateRTMP.bind(this);
        this.getDictionaries = this.getDictionaries.bind(this);
    }

    componentDidMount(): void {
        this.getDictionaries();
    }

    private getDictionaries() {
        Utils.getAvailableLanguages().then((responseDicts:any)=>{
            let options =  responseDicts["languages"].map((obj:any)=>{
                return {
                    key: obj.code,
                    value: obj.code,
                    text: obj.description
                }
            });
            this.setState({languageOptions: options})
        })
    }

    private async onUploadVideo() {
        let {video} = this.state;
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("jwt");
        if (Object.keys(video).length > 0) {
            this.setState({loading: true, pageTitle: "Uploading video - Do not close or refresh the page"});
                axios.request( {
                method: "post",
                url: Utils.host + '/api/storage/streams/interviews/' + this.state.videoId,
                data: video,
                onUploadProgress: (p:any) => {
                    this.setState({progressBar: (100* p.loaded / p.total).toFixed(0)});
                }}).then(()=> {
                    window.location.href = Utils.webapp_dir + 'details/' + this.state.videoId;
                }
            )}
        else {
            this.setState({messageError: 'No video selected', messageVisible: true});
        }
    }

    handleTagChange = (e: any, inputChange: any) => {
        this.setState({tag: inputChange.value});
    };

    handleDateChange = (e: any) => {
        this.setState({videoDate: e})
    };

    handleDismiss = () => {
        this.setState({messageVisible: false})
    };

    addTag() {
        let {tag} = this.state;
        if (tag) {
            let tags = this.state.tags;
            let tagsList = tag.split(" ");
            let newTagsList = tagsList.map((element:string)=>{
                if (element[0] === "#"){
                    return element
                }
                else return "#"+element
            });
            tags = tags.concat(newTagsList);
            tags = tags.filter((v, i, a) => a.indexOf(v) === i);
            tags = tags.filter(e => e !== "#");
            this.setState({tags: tags, tag: ''});
        }
    }

    deleteTag(tag: any) {
        let tags = this.state.tags.filter(e => e !== tag);
        this.setState({tags: tags});
    }

    clickOnSaveMetadata() {
        if (this.state.title && Object.keys(this.state.language).length > 0 ) {
            let timeCreated = Number(new Date());

            let videoMetadata: model.VideoDetails = {
                name: this.state.title,
                language: this.state.language.code,
                second_language: this.state.secondLanguage.code,
                time_created: timeCreated,
                production_date: Number(this.state.videoDate),
                description: this.state.description,
                now_pushing: true,
                source_hls_url: '',
                tags: this.state.tags,
                id: '',
                creator: '',
                thumbnail_url: '',
                finalized: false,
                noSubtitled: this.state.noSubtitled
            };
            Utils.saveMetadataVideoOnDB(videoMetadata).then((responseVideoCreated:any)=>{
                this.setState({metadataSaved: true, videoId: responseVideoCreated.id, pageTitle:this.state.title});
            }).catch((e:any)=>{
                window.location.href = Utils.webapp_dir;
            })
        }
        else {
            this.setState({metadataSaved: false})
        }
    }

    backToMetadata() {
        this.setState({metadataSaved: false, title: '', language: {} as Language, tags:[], rtmplink:''});
    }

    onDrop(e:any) {
        let receivedElement = e[0];
            this.setState({
                video: receivedElement,
                messageError: ''
            })

    }

    generateRTMP() {
        let rtmplink = "rtmp://sharetogo.globaltech.gucci/interviews/event/" + this.state.videoId;
        this.setState({rtmplink: rtmplink})
    }

    renderLoading() {
        return (
            <Grid>
                <Grid.Row columns={3}>
                    <Grid.Column width={4}/>
                    <Grid.Column width={8}>
                <Progress percent={this.state.progressBar} indicating progress={"percent"}/>
                    </Grid.Column>
                    <Grid.Column width={4}/>
                </Grid.Row>
            </Grid>
        );
    }

    renderMetadataForm() {
        let {tag, tags} = this.state;

        return (
            <Form style={{"textAlign": "left"}}>
                <Form.Group widths='equal'>
                    <Form.Field
                        required={true}
                        control={Input}
                        label='Title'
                        placeholder='Title'
                        defaultValue={this.state.title}
                        onChange={(e: any) => {
                            this.setState({title: e.target.value});
                        }}
                        error={!this.state.title}
                    />
                    <Form.Field
                        required={true}
                        control={Select}
                        options={this.state.languageOptions}
                        label={'Language'}
                        placeholder='Language'
                        onChange={(e: any, {value}: any) => {
                            let language = {
                                code: value,
                                description: value
                                };
                            this.setState({language: language});
                        }}
                        search
                        error={!this.state.language.code}
                        key={'mandatoryLanguage'}
                    />
                    <Form.Field
                        control={Select}
                        options={this.state.languageOptions}
                        label={'Second Language'}
                        placeholder={'Second Language'}
                        onChange={(e: any, {dropdownList}: any) => {
                            let secondLanguage = {
                                code: dropdownList,
                                description: dropdownList
                            };
                            this.setState({secondLanguage: secondLanguage});
                        }}
                        search
                        key={'optionalLanguage'}
                    />
                    <Form.Field
                        control={Input}
                        type={'checkbox'}
                        label='No Subtitles'
                        placeholder='noSubtitled'
                        defaultValue={this.state.noSubtitled}
                        onChange={(e: any) => {
                            this.setState({noSubtitled: true});
                        }}
                    />
                </Form.Group>
                <Form.Group inline>
                    <Form.Input
                        icon='tags'
                        iconPosition='left'
                        label={'Tags'}
                        placeholder='Enter tags'
                        width={8}
                        value={tag}
                        onChange={this.handleTagChange}
                    />
                    <Form.Button
                        width={2}
                        content={'Add'}
                        onClick={this.addTag}
                    />
                    <DatePickerInput
                        onChange={this.handleDateChange}
                        value={this.state.videoDate}
                        className='my-react-datepicker'
                    />
                </Form.Group>
                <div>
                    {tags.map((tag) => (
                        <Label style={{"backgroundColor": "#e5dfd9", "color": "#313131"}} key={tag} value={tag}
                               onClick={() => this.deleteTag(tag)}>
                            {tag}
                            <Icon name='delete'/>
                        </Label>
                    ))}
                </div>
                <Form.Field
                    control={TextArea}
                    label='Description'
                    placeholder='Description'
                    onChange={(e: any) => {
                        this.setState({description: e.target.value});
                    }}
                />
                {this.state.messageVisible ?
                    <ErrorMessage onDismiss={this.handleDismiss} messageError={this.state.messageError}/> : null}
                <Button
                    type={'submit'}
                    className={'buttonProcess'}
                    onClick={this.clickOnSaveMetadata}
                >
                    Save
                </Button>
            </Form>
        )
    }


    renderUploadForm() {
        return (
            <Form>
                <Form.Field>
                    <Dropzone onDrop={this.onDrop.bind(this)}
                              multiple={false}>
                        {({getRootProps, getInputProps, isDragActive}) => {
                            return (
                                <div {...getRootProps()} >
                                    <input {...getInputProps()} />
                                    <Segment placeholder className={'imageDragArea'}>
                                        <Fragment>
                                            <Header icon as={"h2"}>
                                                <Icon name='video'
                                                      size={"large"}
                                                      bordered
                                                      style={{"color": "#e5dfd9"}}/>
                                                <div style={{"fontSize": "small"}}>
                                                    {(isDragActive || this.state.video.size > 0) ?
                                                        "VIDEO SELECTED - CLICK 'PROCESS' BELOW" : "BROWSE FOR VIDEO OR DROP IT HERE"
                                                        }
                                                </div>
                                            </Header>
                                        </Fragment>
                                    </Segment>
                                </div>
                            )
                        }}
                    </Dropzone>
                </Form.Field>
                <Message>
                    You can also generate a RTMP link. Click on 'GENERATE' button below.
                </Message>
                <Form.Input
                    action={{
                        labelPosition: 'left',
                        icon: 'linkify',
                        content: 'GENERATE',
                        width: 4,
                        onClick: this.generateRTMP
                    }}
                    readOnly={this.state.rtmplink}
                    actionPosition='left'
                    placeholder='RTMP link...'
                    defaultValue={this.state.rtmplink}
                />
                {this.state.messageVisible ?
                    <ErrorMessage onDismiss={this.handleDismiss} messageError={this.state.messageError}/> : null}
                <Button
                    className={'buttonProcess'}
                    onClick={this.onUploadVideo}
                >
                    Process
                </Button>
                <Button
                    className={'buttonBack'}
                    onClick={this.backToMetadata}
                >
                    Back
                </Button>
            </Form>
        );
    }

    render() {
        return (
            <Container textAlign='center'>
                <Header as='h2' style={{"paddingBottom": "1em"}}>{this.state.pageTitle}</Header>
                {this.state.loading ? this.renderLoading() :
                    <Grid textAlign={"center"} style={{"paddingBottom": "1em"}}>
                        {this.state.metadataSaved ?
                        this.renderUploadForm() :
                        this.renderMetadataForm()
                        }
                    </Grid>
                }
            </Container>

        )
    }
}